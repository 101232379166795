import reduce from "lodash-es/reduce";

import { getAuthToken, hasAuthToken } from "../../utils/auth";

import { API_URL } from "../../utils/environment";

function getAuthConfig() {
  if (hasAuthToken()) {
    return { Authorization: `bearer ${getAuthToken()}` };
  } else {
    return {};
  }
}

function getBaseHeaders() {
  return {
    ...getAuthConfig(),
    "Content-Type": "application/json",
  };
}

function buildQueryParams(query) {
  return reduce(Object.keys(query), (buildQuery, key) => {
    if (query[key] === undefined || query[key] === null) return buildQuery;
    else {
      let res = buildQuery;

      if (res.length > 0) res += "&";

      return `${res}${key}=${query[key]}`;
    }
  }, "");
}

function buildUrl(url) {
  return `${API_URL}${url}`;
}

function parseResponse(response) {
  try {
    try {
      return response.json();
    } catch (e) {
      throw new Error(`Error parse response -> ${response.text()}`);
    }
  } catch (e) {
    return {};
  }
}

const get = async (url, query) => {
  const queryParams = buildQueryParams(query);
  let requestUrl = buildUrl(url);

  if (queryParams.length > 0) requestUrl += `?${queryParams}`;

  const response = await fetch(requestUrl, {
    method: "get",
    headers: new Headers(getBaseHeaders()),
  });

  return parseResponse(response);
};

const post = async (url, object) => {
  const response = await fetch(buildUrl(url), {
    method: "post",
    headers: new Headers(getBaseHeaders()),
    body: JSON.stringify(object),
  });

  return parseResponse(response);
};

const put = async (url, object) => {
  const response = await fetch(buildUrl(url), {
    method: "put",
    headers: new Headers(getBaseHeaders()),
    body: JSON.stringify(object),
  });

  return parseResponse(response);
};

const deleteRequest = async (url, query) => {
  const queryParams = buildQueryParams(query);
  let requestUrl = buildUrl(url);

  if (queryParams.length > 0) requestUrl += `?${queryParams}`;

  const response = await fetch(requestUrl, {
    method: "delete",
    headers: new Headers(getBaseHeaders()),
  });

  return parseResponse(response);
};

export const getBase64 = (url, query = {}) => {
  const queryParams = buildQueryParams(query);
  let requestUrl = buildUrl(url);

  if (queryParams.length > 0) requestUrl += `?${queryParams}`;

  return fetch(requestUrl, {
    method: "get",
    headers: new Headers(getBaseHeaders()),
  })
    .then(response => response.blob())
    .then(blob => new Promise((resolve, reject) => {
      try {
        const reader = new FileReader();

        reader.onload = event => {
          try {
            resolve(event.target.result);
          } catch (e) {
            resolve("");
          }
        };
        reader.readAsDataURL(blob);
      } catch (e) {
        resolve("");
      }
    }));
};

export const fetchJson = async (url, query = {}) => get(url, query);

export const postJson = async (url, dataObject) => post(url, dataObject);

export const putJson = async (url, dataObject) => put(url, dataObject);

export const deleteJson = async (url, dataObject = {}) => deleteRequest(url, dataObject);
