import React from "react";

import Typography from "wabi_ui/core/Typography";
import Grid from "wabi_ui/core/Grid";
import Image from "wabi_ui/core/Image";

import makeStyles from "wabi_ui/core/styles/makeStyles";
import spacing from "wabi_ui/core/styles/spacing";

import { ROLES } from "utils/managers";

const getClasses = makeStyles(({ wabiUI: { colors: { gray } } }) => ({
  root: { ...spacing["p-2"] },
  roleText: { color: gray.main },
  image: {},
}));

const ManagerInfo = ({ manager }) => {
  const classes = getClasses();

  return (
    <Grid container alignItems="center" justify="space-between" className={classes.root}>
      <Grid item xs={3}>
        <Image variant="circle" src={manager.avatar} center className={classes.image} />
      </Grid>

      <Grid item xs={8}>
        <Typography variant="h4">{manager.name}</Typography>
        <Typography variant="body2" className={classes.roleText}>{ROLES[manager.rol].label}</Typography>
      </Grid>
    </Grid>
  );
};

export default ManagerInfo;
