/* eslint-disable react/jsx-props-no-spreading */
import React from "react";

import ModuleLoader from "wabi_ui/core/ModuleLoader";

import Auth from "./auth";
import { moduleConfig } from "./redux";

const AuthComp = props => (<ModuleLoader config={moduleConfig} Component={() => (<Auth {...props} />)} />);

export default AuthComp;
