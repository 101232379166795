const { fetchJson, putJson, postJson, deleteJson, getBase64 } = require("./utils");

export const getManagerInfo = () => fetchJson("/private/managers/me");
export const getManagers = () => fetchJson("/private/managers");

/* USERS */

export const findUsers = ({ query = "", skip = 0 }) => fetchJson("/private/managers/users", { query, skip });
export const createUser = userData => postJson("/private/managers/users", userData);

/* USERS */

/* USER */

export const getUser = id => fetchJson(`/private/managers/users/${id}`);
export const updateUser = (id, toUpdate) => putJson(`/private/managers/users/${id}`, toUpdate);
export const deleteUser = id => deleteJson(`/private/managers/users/${id}`);
export const removeUser = id => deleteJson(`/private/managers/users/${id}/remove`);

export const getUserLogs = id => fetchJson(`/private/managers/users/${id}/logs`);

export const getDiscounts = userId => fetchJson(`/private/managers/users/${userId}/discounts`);
export const createDiscount = (userId, data) => postJson(`/private/managers/users/${userId}/discounts`, data);
export const updateDiscount = (userId, id, data) => putJson(`/private/managers/users/${userId}/discounts/${id}`, data);
export const removeDiscount = (userId, id) => deleteJson(`/private/managers/users/${userId}/discounts/${id}`);

export const getUserNotes = id => fetchJson(`/private/managers/users/${id}/notes`);
export const createUserNote = (id, noteData) => postJson(`/private/managers/users/${id}/notes`, noteData);
export const deleteUserNote = (id, noteId) => deleteJson(`/private/managers/users/${id}/notes/${noteId}`);

export const getUserFiles = id => fetchJson(`/private/managers/users/${id}/files`);
export const addUserFile = (id, fileData) => postJson(`/private/managers/users/${id}/files`, fileData);
export const deleteUserFile = (userId, fileId) => deleteJson(`/private/managers/users/${userId}/files/${fileId}`);
export const updateUserFile = (userId, fileId, fileData) => putJson(`/private/managers/users/${userId}/files/${fileId}`, fileData);

export const createService = (userId, serviceData) => postJson(`/private/managers/users/${userId}/services`, serviceData);
export const getUserServices = userId => fetchJson(`/private/managers/users/${userId}/services`);

export const getUserPayments = userId => fetchJson(`/private/managers/users/${userId}/payments`);
export const createUserPayment = (userId, payment) => postJson(`/private/managers/users/${userId}/payments`, payment);
export const createUserUnPayment = (userId, payment) => postJson(`/private/managers/users/${userId}/payments/unpayment`, payment);
export const updateUserPayment = (userId, paymentId, toUpdate) => putJson(`/private/managers/users/${userId}/payments/${paymentId}`, toUpdate);
export const deleteUserPayment = (userId, paymentId) => deleteJson(`/private/managers/users/${userId}/payments/${paymentId}`);

export const getUserCards = userId => fetchJson(`/private/managers/users/${userId}/cards`);
export const addUserCard = (userId, cardData) => postJson(`/private/managers/users/${userId}/cards`, cardData);
export const updateUserCard = (userId, cardId, updateData) => putJson(`/private/managers/users/${userId}/cards/${cardId}`, updateData);
export const deleteUserCard = (userId, cardId) => deleteJson(`/private/managers/users/${userId}/cards/${cardId}`);

export const getDocumentDocumentation = userId => getBase64(`/private/managers/users/${userId}/documents/dni`);

export const getUserCheckoutSetup = userId => fetchJson(`/private/managers/users/${userId}/checkout_setup`);
export const resetCheckoutConfig = userId => postJson(`/private/managers/users/${userId}/checkout_reset`);
export const getUserFibreAvailability = userId => fetchJson(`/private/managers/users/${userId}/fibre_availability`);

/* USER */

/* CARDS */

/* TASK */

export const getTasks = (query = {}) => fetchJson("/private/managers/tasks/search", query);
export const getTask = id => fetchJson(`/private/managers/tasks/${id}`);
export const createTask = task => postJson("/private/managers/tasks", task);
export const getCurrentTasks = () => fetchJson("/private/managers/tasks");
export const getOrAssingTasks = () => fetchJson("/private/managers/tasks/new");

export const pospuseTask = (id, hours = 1) => putJson(`/private/managers/tasks/${id}`, { hours });
export const doneTask = id => postJson(`/private/managers/tasks/${id}`);

export const getTaskNotes = id => fetchJson(`/private/managers/tasks/${id}/notes`);
export const addNoteToTask = (id, note) => postJson(`/private/managers/tasks/${id}/notes`, note);
export const deleteNoteTask = (id, noteId) => deleteJson(`/private/managers/tasks/${id}/notes/${noteId}`);

export const getTaskLogs = id => fetchJson(`/private/managers/tasks/${id}/logs`);

/* TASK */

/* DOCUMENTS */

export const getDocument = serviceId => getBase64(`/private/managers/documents/${serviceId}`);
export const getBajaDocument = serviceId => getBase64(`/private/managers/documents/baja/${serviceId}`);
export const getBajaVoluntariaDocument = serviceId => getBase64(`/private/managers/documents/baja_voluntaria/${serviceId}`);

/* DOCUMENTS */

/* FIBRE */

export const checkFibreAddress = address => fetchJson("/private/managers/fibre/check/address", address);

/* FIBRE */

/* INFLUENCERS */

export const isValidPromo = promoCode => postJson("/private/managers/influencers/validate", { promoCode });
export const setPromoCodeUser = (id, promoCode) => postJson(`/private/managers/influencers/users/${id}`, { promoCode });

export const getInfluencers = () => fetchJson("/private/managers/influencers");
export const createInfluencer = influencerData => postJson("/private/managers/influencers", influencerData);
export const getInfluencer = id => fetchJson(`/private/managers/influencers/${id}`);
export const updateInfluencer = (id, influencerData) => putJson(`/private/managers/influencers/${id}`, influencerData);

export const getTotalUsersCreated = (id, days = 7) => fetchJson(`/private/managers/influencers/${id}/metrics/users-created`, { days });
export const getTotalUsersEnabled = (id, days = 7) => fetchJson(`/private/managers/influencers/${id}/metrics/users-enabled`, { days });
export const getTotalBilling = id => fetchJson(`/private/managers/influencers/${id}/metrics/billing`);
export const getFunnel = (id, days = 7) => fetchJson(`/private/managers/influencers/${id}/metrics/funnel`, { days });

/* INFLUENCERS */

/* INTEGRATIONS */

export const launchService = id => postJson(`/private/managers/integrations/services/${id}/launch`);
export const setServiceLimits = id => postJson(`/private/managers/integrations/services/${id}/limits`);

export const getOutPortasFinetwork = (total = 100) => fetchJson("/private/managers/integrations/finetwork/outgoing", { total });

/* INTEGRATIONS */

/* ENERGY */

export const getCupsDetails = cups => fetchJson("/private/managers/energy/cups", { cups });
export const getAddressDetails = technicalId => fetchJson("/private/managers/energy/cups/address", { technicalId });
export const getAddressOptions = address => fetchJson("/private/managers/energy/address-options", address);
export const getEnergySimulation = info => fetchJson("/private/managers/energy/simulation", info);
export const getEnergyAddressSimulation = technicalId => fetchJson("/private/managers/energy/simulation/address", { technicalId });

/* ENERGY */
