import reduce from "lodash-es/reduce";

export const ROUTES = {
  root: "/",
  metrics: "/metrics",
  users: {
    root: "/users",
    search: "/users/search",
    metrics: "/users/metrics",
    view: "/users/:id",
    create: "/users/create",
    letter: "/users/:id/letter",
  },
  payments: {
    root: "/payments",
    search: "/payments/search",
    create: "/payments/uploader",
    simulate: "/payments/simulate/bill",
    metrics: "/payments/metrics",
    stripe: "/payments/simulate/stripe",
  },
  tasks: {
    root: "/tasks",
    search: "/tasks/search",
    create: "/tasks/create",
    list: "/tasks/list",
    view: "/tasks/view/:id",
    metrics: "/tasks/metrics",
  },
  fibre: {
    root: "/fibre",
    check: "/fibre/check",
  },
  notifications: {
    root: "/notifications",
    pending: "/notifications/pending",
  },
  influencers: {
    root: "/influencers",
    view: "/influencers/:id",
    list: "/influencers/search",
  },
  integrations: {
    root: "/integrations",
    launch: "/integrations/launch",
    limits: "/integration/limits",
    outgoing: "/integrations/outgoing",
  },
  energy: {
    root: "/energy",
    comparative: "/energy/comparative",
    cups: "/energy/cups",
  },
};

export const getLink = (route, params = {}) => reduce(
  Object.keys(params),
  (link, key) => link.replace(new RegExp(`:${key}`, "gi"), params[key]),
  route,
);

export default ROUTES;
