/* eslint-disable object-curly-spacing */
/* eslint-disable object-curly-newline */
/* eslint-disable react/no-danger */
import React, { Suspense, lazy, PureComponent } from "react";
import { withRouter, Switch, Route } from "react-router-dom";

import asyncComponent from "wabi_ui/core/AsyncComponent";
import PageLoading from "wabi_ui/core/PageLoading";
import { getCurrentConfig } from "wabi_ui/core/Provider/themeConfig";

import Grid from "wabi_ui/core/Grid";

import { ROUTES } from "utils/routes";

import Sidebar from "layout/Sidebar";
import PageWrapper from "layout/Page";

const { wabiUI: { colors: { gray } } } = getCurrentConfig();

const MetricView = lazy(() => import("pages/Metrics"));

const SearchUserView = lazy(() => import("./pages/Users/Search"));
const UserView = lazy(() => import("./pages/Users/View"));
const CreateUser = lazy(() => import("./pages/Users/Create"));
const LetterUser = lazy(() => import("./pages/Users/Letter"));
const MetricsUser = lazy(() => import("./pages/Users/Metrics"));

const PaymentsCreate = lazy(() => import("./pages/Payments/Create"));
const PaymentsSimulate = lazy(() => import("./pages/Payments/Simulate"));
const PaymentsMetrics = lazy(() => import("./pages/Payments/Metrics"));
const PaymentsSimulateStripe = lazy(() => import("./pages/Payments/StripeSimulate"));

const TasksList = lazy(() => import("./pages/Tasks/List"));
const TasksSearch = lazy(() => import("./pages/Tasks/Search"));
const TaskView = lazy(() => import("./pages/Tasks/View"));
const TaskCreate = lazy(() => import("./pages/Tasks/Create"));
const TaskMetrics = lazy(() => import("./pages/Tasks/Metrics"));

const CheckFibreView = lazy(() => import("./pages/Fibre/Check"));

const PendingNotificationsView = lazy(() => import("./pages/Notifications/Pending"));

const InfluencerListView = lazy(() => import("./pages/Influencers/List"));
const InfluencerView = lazy(() => import("./pages/Influencers/View"));

const LaunchPortaView = lazy(() => import("./pages/Integrations/Launch"));
const ServiceLimitsView = lazy(() => import("./pages/Integrations/Limits"));
const OutgoingPortasView = lazy(() => import("./pages/Integrations/outgoing"));

const EnergyComparatorView = lazy(() => import("./pages/Energy/Comparative"));
const EnergyCUPSView = lazy(() => import("./pages/Energy/Details"));

class Routes extends PureComponent {
  componentDidUpdate(prevProps) {
    // eslint-disable-next-line react/destructuring-assignment
    if (this.props.location.pathname !== prevProps.location.pathname) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    const { location } = this.props;

    return (
      <Suspense fallback={<PageLoading />}>
        <style dangerouslySetInnerHTML={{
          __html: `
            body, html { background-color: ${gray.lighter}; }
          `}}
        />

        <Sidebar xs={8} sm={6} md={3} lg={2} />

        <Grid container justify="flex-end">
          <Grid item xs={12} md={9} lg={10}>
            <PageWrapper>
              <Switch location={location}>
                <Route path={ROUTES.metrics} component={asyncComponent(MetricView)} />

                <Route path={ROUTES.users.search} component={asyncComponent(SearchUserView)} />
                <Route path={ROUTES.users.metrics} component={asyncComponent(MetricsUser)} />
                <Route path={ROUTES.users.letter} component={asyncComponent(LetterUser)} />
                <Route path={ROUTES.users.create} component={asyncComponent(CreateUser)} />
                <Route path={ROUTES.users.view} component={asyncComponent(UserView)} />

                <Route path={ROUTES.payments.create} component={asyncComponent(PaymentsCreate)} />
                <Route path={ROUTES.payments.simulate} component={asyncComponent(PaymentsSimulate)} />
                <Route path={ROUTES.payments.stripe} component={asyncComponent(PaymentsSimulateStripe)} />
                <Route path={ROUTES.payments.metrics} component={asyncComponent(PaymentsMetrics)} />

                <Route path={ROUTES.tasks.list} component={asyncComponent(TasksList)} />
                <Route path={ROUTES.tasks.search} component={asyncComponent(TasksSearch)} />
                <Route path={ROUTES.tasks.create} component={asyncComponent(TaskCreate)} />
                <Route path={ROUTES.tasks.metrics} component={asyncComponent(TaskMetrics)} />
                <Route path={ROUTES.tasks.view} component={asyncComponent(TaskView)} />

                <Route path={ROUTES.fibre.check} component={asyncComponent(CheckFibreView)} />
                <Route path={ROUTES.notifications.pending} component={asyncComponent(PendingNotificationsView)} />

                <Route path={ROUTES.influencers.list} component={asyncComponent(InfluencerListView)} />
                <Route path={ROUTES.influencers.view} component={asyncComponent(InfluencerView)} />

                <Route path={ROUTES.integrations.launch} component={asyncComponent(LaunchPortaView)} />
                <Route path={ROUTES.integrations.limits} component={asyncComponent(ServiceLimitsView)} />
                <Route path={ROUTES.integrations.outgoing} component={asyncComponent(OutgoingPortasView)} />

                <Route path={ROUTES.energy.comparative} component={asyncComponent(EnergyComparatorView)} />
                <Route path={ROUTES.energy.cups} component={asyncComponent(EnergyCUPSView)} />

                <Route path={ROUTES.root} component={asyncComponent(MetricView)} />
              </Switch>
            </PageWrapper>
          </Grid>
        </Grid>
      </Suspense>
    );
  }
}

export default withRouter(Routes);
