/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-multi-comp */
import React, { useState, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import classnames from "classnames";

import Typography from "wabi_ui/core/Typography";
import Hidden from "wabi_ui/core/Hidden";
import AppBar from "wabi_ui/core/AppBar";
import Toolbar from "wabi_ui/core/Toolbar";
import Icon from "wabi_ui/core/Icon";
import IconButton from "wabi_ui/core/IconButton";

import makeStyles from "wabi_ui/core/styles/makeStyles";
import spacing from "wabi_ui/core/styles/spacing";
import { NormalShadow } from "wabi_ui/core/styles/others";

import { getStateValue } from "wabi_ui/utils/redux";

import { ROUTES } from "utils/routes";

import ManagerInfo from "./components/ManagerInfo";
import NavLink from "./components/NavLink";

const getClasses = makeStyles(({ breakpoints, wabiUI: { colors: { gray, primary } } }) => ({
  noPrint: { "@media print": { display: "none" } },
  sidebarRoot: ({ xs, sm, md, lg }) => ({
    ...NormalShadow,
    [breakpoints.only("xs")]: { width: `${(100 * xs) / 12}%` },
    [breakpoints.only("sm")]: { width: `${(100 * sm) / 12}%` },
    [breakpoints.only("md")]: { width: `${(100 * md) / 12}%` },
    [breakpoints.up("lg")]: { width: `${(100 * lg) / 12}%` },
    backgroundColor: "white",
    position: "fixed",
    maxHeight: "100vh",
    minHeight: "100vh",
    overflowY: "auto",
    zIndex: 1000,
  }),
  logo: { ...spacing["my-2"], width: "auto !important", height: "40px!important" },
  separator: { height: "1px", minHeight: "1px", backgroundColor: gray.main },
  navOption: {
    ...spacing["px-3"],
    ...spacing["py-2"],
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    borderBottom: `1px solid ${gray.light}`,

    "&:hover": { backgroundColor: gray.light },
  },
  navOptionIcon: { ...spacing["mr-2"] },
  navOptionSelected: { color: primary.main },
  openSidebarButton: { ...spacing["ml-auto"] },
}));

const Sidebar = props => {
  const { manager } = props;
  const [showSidebar, setShowSidebar] = useState(false);

  const classes = getClasses(props);

  const isSelectedLink = path => window.location.pathname === path;
  const isIncludedRoute = path => window.location.pathname.includes(path);

  const printSidebar = () => (
    <div className={classnames(classes.sidebarRoot, classes.noPrint)}>
      <ManagerInfo manager={manager} />

      <div className={classes.separator} />

      <NavLink
        className={classes.navOption}
        icon="tachometer-alt"
        options={[{ text: "Métricas", path: ROUTES.root }]}
        selected={isSelectedLink(ROUTES.root)}
      />

      <NavLink
        className={classes.navOption}
        icon="user"
        options={[
          { text: "Usuarios" },
          { text: "Buscador", path: ROUTES.users.search },
          { text: "Añadir Usuario", path: ROUTES.users.create },
          { text: "Metricas", path: ROUTES.users.metrics },
        ]}
        selected={isIncludedRoute(ROUTES.users.root)}
      />

      <NavLink
        className={classes.navOption}
        icon="tasks"
        options={[
          { text: "Tareas" },
          { text: "Buscador", path: ROUTES.tasks.search },
          { text: "Asignadas", path: ROUTES.tasks.list },
          { text: "Añadir", path: ROUTES.tasks.create },
          { text: "Métricas", path: ROUTES.tasks.metrics },
        ]}
        selected={isIncludedRoute(ROUTES.tasks.root)}
      />

      <NavLink
        className={classes.navOption}
        icon="euro-sign"
        options={[
          { text: "Pagos" },
          { text: "Añadir Impagos", path: ROUTES.payments.create },
          { text: "Simular (Factura)", path: ROUTES.payments.simulate },
          { text: "Ejecutar Pago", path: ROUTES.payments.stripe },
          { text: "Metricas", path: ROUTES.payments.metrics },
        ]}
        selected={isIncludedRoute(ROUTES.payments.root)}
      />

      <NavLink
        className={classes.navOption}
        icon="wifi"
        options={[{ text: "Fibra", path: ROUTES.fibre.check }]}
        selected={isSelectedLink(ROUTES.fibre.check)}
      />

      <NavLink
        className={classes.navOption}
        icon="whatsapp"
        options={[{ text: "Whatsapp", path: ROUTES.notifications.pending }]}
        selected={isIncludedRoute(ROUTES.notifications.root)}
      />

      <NavLink
        className={classes.navOption}
        icon="bullhorn"
        options={[{ text: "Influencers", path: ROUTES.influencers.list }]}
        selected={isIncludedRoute(ROUTES.influencers.root)}
      />

      <NavLink
        className={classes.navOption}
        icon="cogs"
        options={[
          { text: "Integraciones" },
          { text: "Lanzar Porta", path: ROUTES.integrations.launch },
          { text: "Limites", path: ROUTES.integrations.limits },
          { text: "Portas Salientes", path: ROUTES.integrations.outgoing },
        ]}
        selected={isIncludedRoute(ROUTES.integrations.root)}
      />

      <NavLink
        className={classes.navOption}
        icon="infinity"
        options={[
          { text: "Luz" },
          { text: "Comparativa", path: ROUTES.energy.comparative },
          { text: "CUPS", path: ROUTES.energy.cups },
        ]}
        selected={isIncludedRoute(ROUTES.energy.root)}
      />
    </div>
  );

  return (
    <Fragment>
      <Hidden mdUp>
        <AppBar position="static" color="primary" className={classes.noPrint}>
          <Toolbar>
            <Typography variant="h4" weight="bold" className={classes.grow}>
              Dashboard
            </Typography>

            <IconButton className={classes.openSidebarButton} onClick={() => setShowSidebar(!showSidebar)} icon={<Icon icon="bars" size="1x" />} />
          </Toolbar>
        </AppBar>

        {showSidebar && printSidebar()}
      </Hidden>

      <Hidden smDown>
        {printSidebar()}
      </Hidden>
    </Fragment>
  );
};

const mapStateToProps = ({ managerState }) => getStateValue({ state: managerState, values: { manager: {} } });

export default connect(mapStateToProps)(withRouter(Sidebar));
