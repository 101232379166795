export const INIT_CONTACT_VIEW_LAUNCH = "INIT_CONTACT_VIEW_LAUNCH";
export const FINISH_CONTACT_VIEW_LAUNCH = "FINISH_CONTACT_VIEW_LAUNCH";
export const INIT_UPDATE_USER_LAUNCH = "INIT_UPDATE_USER_LAUNCH";
export const FINISH_UPDATE_USER_LAUNCH = "FINISH_UPDATE_USER_LAUNCH";

export const UPDATE_USER_LAUNCH = "UPDATE_USER_LAUNCH";
export const INIT_ADD_NOTE_LAUNCH = "INIT_ADD_NOTE_LAUNCH";
export const FINISH_ADD_NOTE_LAUNCH = "FINISH_ADD_NOTE_LAUNCH";
export const INIT_END_TASK_LAUNCH = "INIT_END_TASK_LAUNCH";
export const FINISH_END_TASK_LAUNCH = "FINISH_END_TASK_LAUNCH";
export const FINISH_UPDATE_PAYMENT_LAUNCH = "FINISH_UPDATE_PAYMENT_LAUNCH";

export const INIT_DOCUMENTATION_VIEW_LAUNCH = "INIT_DOCUMENTATION_VIEW_LAUNCH";
export const FINISH_DOCUMENTATION_VIEW_LAUNCH = "FINISH_DOCUMENTATION_VIEW_LAUNCH";

export const INIT_METRICS_LAUNCH = "INIT_METRICS_LAUNCH";
export const FINISH_METRICS_LAUNCH = "FINISH_METRICS_LAUNCH";

/* V2 */

export const AUTH_MANAGER_LAUNCH = "AUTH_MANAGER_LAUNCH";
export const AUTH_MANAGER_FINISH = "AUTH_MANAGER_FINISH";

export const SEARCH_USER_LAUNCH = "SEARCH_USER_LAUNCH";
export const SEARCH_USER_FINISH = "SEARCH_USER_FINISH";

export const USER_VIEW_LAUNCH = "USER_VIEW_LAUNCH";
export const USER_VIEW_FINISH = "USER_VIEW_FINISH";
export const USER_VIEW_UPDATE_USER_LOCAL = "USER_VIEW_UPDATE_USER_LOCAL";
export const USER_VIEW_UPDATE_USER_LOCAL_PARTIAL = "USER_VIEW_UPDATE_USER_LOCAL_PARTIAL";
export const USER_VIEW_SAVE_USER_LAUNCH = "USER_VIEW_SAVE_USER_LAUNCH";
export const USER_VIEW_SAVE_USER_FINISH = "USER_VIEW_SAVE_USER_FINISH";
export const USER_VIEW_DELETE_USER_LAUNCH = "USER_VIEW_DELETE_USER_LAUNCH";
export const USER_VIEW_DELETE_USER_FINISH = "USER_VIEW_DELETE_USER_FINISH";
export const USER_VIEW_REMOVE_USER_LAUNCH = "USER_VIEW_REMOVE_USER_LAUNCH";
export const USER_VIEW_REMOVE_USER_FINISH = "USER_VIEW_REMOVE_USER_FINISH";

export const USER_VIEW_ADD_NOTE_USER_LAUNCH = "USER_VIEW_ADD_NOTE_USER_LAUNCH";
export const USER_VIEW_ADD_NOTE_USER_FINISH = "USER_VIEW_ADD_NOTE_USER_FINISH";
export const USER_VIEW_DELETE_NOTE_USER_LAUNCH = "USER_VIEW_DELETE_NOTE_USER_LAUNCH";
export const USER_VIEW_DELETE_NOTE_USER_FINISH = "USER_VIEW_DELETE_NOTE_USER_FINISH";

export const USER_VIEW_ADD_FILE_USER_LAUNCH = "USER_VIEW_ADD_FILE_USER_LAUNCH";
export const USER_VIEW_ADD_FILE_USER_FINISH = "USER_VIEW_ADD_FILE_USER_FINISH";
export const USER_VIEW_REMOVE_FILE_USER_LAUNCH = "USER_VIEW_REMOVE_FILE_USER_LAUNCH";
export const USER_VIEW_REMOVE_FILE_USER_FINISH = "USER_VIEW_REMOVE_FILE_USER_FINISH";

export const USER_VIEW_LOAD_SERVICES_LAUNCH = "USER_VIEW_LOAD_SERVICES_LAUNCH";
export const USER_VIEW_LOAD_SERVICES_FINISH = "USER_VIEW_LOAD_SERVICES_FINISH";
export const USER_VIEW_UPDATE_SERVICE_FINISH = "USER_VIEW_UPDATE_SERVICE_FINISH";
export const USER_VIEW_DELETE_SERVICE_FINISH = "USER_VIEW_DELETE_SERVICE_FINISH";

export const USER_VIEW_CREATE_PAYMENT_FINISH = "USER_VIEW_CREATE_PAYMENT_FINISH";
export const USER_VIEW_UPDATE_PAYMENT_FINISH = "USER_VIEW_UPDATE_PAYMENT_FINISH";
export const USER_VIEW_DELETE_PAYMENT_FINISH = "USER_VIEW_DELETE_PAYMENT_FINISH";

export const USER_VIEW_CREATE_CARD_FINISH = "USER_VIEW_CREATE_CARD_FINISH";
export const USER_VIEW_UPDATE_CARD_FINISH = "USER_VIEW_UPDATE_CARD_FINISH";
export const USER_VIEW_DELETE_CARD_FINISH = "USER_VIEW_DELETE_CARD_FINISH";
export const USER_VIEW_RESET_CARD_CONFIG = "USER_VIEW_RESET_CARD_CONFIG";

export const USER_VIEW_CREATE_DISCOUNT_FINISH = "USER_VIEW_CREATE_DISCOUNT_FINISH";
export const USER_VIEW_UPDATE_DISCOUNT_FINISH = "USER_VIEW_UPDATE_DISCOUNT_FINISH";
export const USER_VIEW_DELETE_DISCOUNT_FINISH = "USER_VIEW_DELETE_DISCOUNT_FINISH";

export const LOAD_TASK_LAUNCH = "LOAD_TASK_LAUNCH";
export const LOAD_TASK_FINISH = "LOAD_TASK_FINISH";

export const TASK_VIEW_LAUNCH = "TASK_VIEW_LAUNCH";
export const TASK_VIEW_FINISH = "TASK_VIEW_FINISH";
export const TASK_VIEW_ADD_NOTE_LAUNCH = "TASK_VIEW_ADD_NOTE_LAUNCH";
export const TASK_VIEW_ADD_NOTE_FINISH = "TASK_VIEW_ADD_NOTE_FINISH";
export const TASK_VIEW_DELETE_NOTE_LAUNCH = "TASK_VIEW_DELETE_NOTE_LAUNCH";
export const TASK_VIEW_DELETE_NOTE_FINISH = "TASK_VIEW_DELETE_NOTE_FINISH";
export const TASK_VIEW_UPDATE_SERVICE_FINISH = "TASK_VIEW_UPDATE_SERVICE_FINISH";
export const TASK_VIEW_DELETE_SERVICE_FINISH = "TASK_VIEW_DELETE_SERVICE_FINISH";
export const TASK_VIEW_POSPOUSE_LAUNCH = "TASK_VIEW_POSPOUSE_LAUNCH";
export const TASK_VIEW_POSPOUSE_FINISH = "TASK_VIEW_POSPOUSE_FINISH";
export const TASK_VIEW_DONE_LAUNCH = "TASK_VIEW_DONE_LAUNCH";
export const TASK_VIEW_DONE_FINISH = "TASK_VIEW_DONE_FINISH";

export const LOAD_PENDING_NOTIFICATIONS_LAUNCH = "LOAD_PENDING_NOTIFICATIONS_LAUNCH";
export const LOAD_PENDING_NOTIFICATIONS_FINISH = "LOAD_PENDING_NOTIFICATIONS_FINISH";

export const LOAD_INFLUENCERS_LIST_LAUNCH = "LOAD_INFLUENCERS_LIST_LAUNCH";
export const LOAD_INFLUENCERS_LIST_FINISH = "LOAD_INFLUENCERS_LIST_FINISH";
export const ADD_INFLUENCER_LIST_FINISH = "ADD_INFLUENCER_LIST_FINISH";
export const UPDATE_INFLUENCER_LIST_FINISH = "UPDATE_INFLUENCER_LIST_FINISH";
export const LOAD_INFLUENCER_LAUNCH = "LOAD_INFLUENCER_LAUNCH";
export const LOAD_INFLUENCER_FINISH = "LOAD_INFLUENCER_FINISH";

export const LOAD_BAJA_TASKS_LAUNCH = "LOAD_BAJA_TASKS_LAUNCH";
export const LOAD_BAJA_TASKS_FINISH = "LOAD_BAJA_TASKS_FINISH";
