import React from "react";
import { BrowserRouter } from "react-router-dom";

import WabiProvider from "wabi_ui/core/Provider";

import Routes from "./routes";
import Auth from "./components/Auth";

const App = () => (
  <WabiProvider redux>
    <Auth>
      <BrowserRouter>
        <Routes />
      </BrowserRouter>
    </Auth>
  </WabiProvider>
);

export default App;
