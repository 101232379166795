const AUTH_TOKEN = "AUTH_TOKEN_MANAGER";

export const getAuthToken = () => localStorage.getItem(AUTH_TOKEN);

export const hasAuthToken = () => {
  const token = localStorage.getItem(AUTH_TOKEN);

  return !!token && token.trim().length > 0;
};

export const saveAuthToken = token => {
  localStorage.setItem(AUTH_TOKEN, token);
};
