/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Fragment, useState } from "react";
import { useHistory } from "react-router";
import classnames from "classnames";
import map from "lodash-es/map";
import takeRight from "lodash-es/takeRight";

import Typography from "wabi_ui/core/Typography";
import Icon from "wabi_ui/core/Icon";

import makeStyles from "wabi_ui/core/styles/makeStyles";
import spacing from "wabi_ui/core/styles/spacing";

const getClasses = makeStyles(({ wabiUI: { colors: { primary } } }) => ({
  icon: { ...spacing["mr-2"] },
  subOption: {
    ...spacing["ml-6"],
    ...spacing["pt-1"],

    "&:hover > p": { fontWeight: "bold !important" },
  },
  selected: { color: primary.main },
  optionsWrapper: { display: "block !important" },
  optionsTitle: { display: "flex", alignItems: "center" },
}));

const NavLink = ({ className, options, icon, selected, name }) => {
  const classes = getClasses();
  const history = useHistory();

  const [isOpen, setIsOpen] = useState(false);

  if (!options || options.length === 0) return (<Fragment />);

  if (options.length === 1) {
    const [{ text, path }] = options;

    return (
      <div className={classnames(className, selected ? classes.selected : "")} onClick={() => history.push(path)}>
        <Icon className={classes.icon} icon={icon} size="1x" />
        <Typography variant="body1" align="center" weight={selected ? "bold" : "normal"}>{text}</Typography>
      </div>
    );
  }

  const [{ text }] = options;

  return (
    <div className={classnames(className, classes.optionsWrapper)} onClick={() => setIsOpen(!isOpen)}>
      <div className={classnames(classes.optionsTitle, selected ? classes.selected : "")}>
        <Icon className={classes.icon} icon={icon} size="1x" />
        <Typography variant="body1" align="left" weight={selected ? "bold" : "normal"}>{text}</Typography>
      </div>

      {isOpen && map(takeRight(options, options.length - 1), (option, index) => (
        <div key={`suboption-${name}-${index}`} className={classes.subOption} onClick={() => history.push(option.path)}>
          <Typography variant="body1" align="left">{option.text}</Typography>
        </div>
      ))}
    </div>
  );
};

export default NavLink;
