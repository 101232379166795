/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */
import produce from "immer";
import forEach from "lodash-es/forEach";

import { launchAction } from "wabi_ui/utils/redux";
import { isValidString } from "wabi_ui/utils/forms";

import { AUTH_MANAGER_LAUNCH, AUTH_MANAGER_FINISH } from "utils/actions";
import { getAuthToken, saveAuthToken } from "utils/auth";

import { getManagerInfo, getManagers } from "api/manager";
import { auth } from "api/auth";

export const init = hmac => async dispatch => {
  launchAction(dispatch, AUTH_MANAGER_LAUNCH, {});

  const authToken = getAuthToken();
  let manager = false;

  if (!!authToken && !isValidString(hmac, 3, 200)) {
    manager = await getManagerInfo();
  }

  if (!!hmac && (!manager || !!manager.err)) {
    const { token } = await auth(hmac);

    saveAuthToken(token);

    manager = await getManagerInfo();

    if (!manager || !!manager.err) {
      manager = false;
    }
  }

  if (!manager || !!manager.err) {
    manager = false;
    saveAuthToken("");
  }

  if (!!manager) {
    const managers = await getManagers().then(list => {
      const res = { [null]: { name: "BOT", _id: null } };

      if (!!list) {
        forEach(list, m => {
          res[m._id] = m;
        });
      }

      return res;
    });

    if (manager) { manager.rol = "MANAGER"; }

    launchAction(dispatch, AUTH_MANAGER_FINISH, { manager, managers });
  } else {
    launchAction(dispatch, AUTH_MANAGER_FINISH, { manager, managers: {} });
  }
};

const defaultState = { isLoading: true, manager: false, managers: {} };

const reducer = (state, action) => (produce(state || defaultState, possibleState => {
  switch (action.type) {
    case AUTH_MANAGER_LAUNCH: {
      possibleState.isLoading = true;

      break;
    }
    case AUTH_MANAGER_FINISH: {
      possibleState.isLoading = false;

      possibleState.manager = action.payload.manager;
      possibleState.managers = action.payload.managers;

      break;
    }
    default: {
      // do nothing
    }
  }
})
);

export const moduleConfig = {
  id: "authManagerModule",
  reducerMap: { managerState: reducer },
  initialActions: [],
  finalActions: [],
};
