import React from "react";

import makeStyles from "wabi_ui/core/styles/makeStyles";
import spacing from "wabi_ui/core/styles/spacing";

const getClasses = makeStyles({
  root: { ...spacing["w-100"] },
  content: { ...spacing["pt-4"], ...spacing["px-2"], ...spacing["mx-auto"], maxWidth: "1200px" },
});

const Page = ({ children }) => {
  const classes = getClasses();

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        {children}
      </div>
    </div>
  );
};

export default Page;
