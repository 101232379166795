import React, { useEffect, Fragment } from "react";
import QueryString from "query-string";
import { connect } from "react-redux";

import PageLoading from "wabi_ui/core/PageLoading";
import Grid from "wabi_ui/core/Grid";
import Typography from "wabi_ui/core/Typography";
import Paper from "wabi_ui/core/Paper";

import MakeStyles from "wabi_ui/core/styles/makeStyles";
import spacing from "wabi_ui/core/styles/spacing";

import { getStateValue } from "wabi_ui/utils/redux";

import { init as authentication } from "./redux";

const getClasses = MakeStyles({ root: spacing["mt-3"] });

const Auth = ({ isLoading, manager, children, dispatch }) => {
  const classes = getClasses();

  useEffect(() => {
    const { hmac } = QueryString.parse(window.location.search);

    authentication(hmac)(dispatch);
  }, [dispatch]);

  if (isLoading) return (<PageLoading />);

  if (!manager) {
    return (
      <Grid container justify="center">
        <Grid item xs={10} sm={8} md={6}>
          <Paper className={classes.root}>
            <Typography variant="h2" center weight="bold">
              El token de sessión es invalido, por favor genere uno nuevo desde Slack
            </Typography>
          </Paper>
        </Grid>
      </Grid>
    );
  }

  return (
    <Fragment>
      {children}
    </Fragment>
  );
};

const mapStateToProps = ({ managerState }) => getStateValue({ state: managerState, values: { isLoading: true, manager: false } });

export default connect(mapStateToProps)(Auth);
